import React from 'react';
import {FaTelegramPlane, FaVk} from 'react-icons/fa';
import {FaGitFlic} from "./icons/FaGitflic";
import photo from './photo.jpg';
import './App.css';

function App() {
    return (
        <div className="app">
            <header className="app-header">
                <div className="app-logo">
                    <img src={photo} alt="logo"/>
                </div>

                <h1 className={'app-full-name'}><p>ЕГОР</p>ЧЕРНЫШОВ</h1>

                <div className={'app-skills'}>
                    <p>c# golang php javascript sql</p>
                    <p>linux git docker ci/cd tests</p>
                </div>

                <p className={'app-links'}>
                    <a className={'app-links__telegram'} href="https://t.me/jarogor"><FaTelegramPlane/></a>
                    <a className={'app-links__gitflic'} href="https://gitflic.ru/user/jarogor"><FaGitFlic/></a>
                    <a className={'app-links__vk'} href="https://vk.com/jarogor"><FaVk/></a>
                </p>
            </header>
        </div>
    );
}

export default App;
